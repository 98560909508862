<template>
    <div class="shipping-upgrade-section">
        <div v-if="shipping_options.length > 0">
            <div class="row">
                <div class="col">
                    <h3>
                        GET EXPEDITED SHIPPING
                        <InfoPopoverComponent title="Expedited Shipping">
                            <p>
                                Receive your portrait in up to 5 working days,
                                after your approval.
                            </p>
                        </InfoPopoverComponent>
                    </h3>
                </div>
            </div>
            <div class="row options">
                <div
                    class="col"
                    v-for="option in shipping_options"
                    :key="option.label"
                >
                    <a
                        @click.prevent="selectOption(option)"
                        href="#"
                        class="option"
                        ref="shipping_upgrade_option"
                        :class="{
                            selected: option.selected,
                            disabled: option.disabled,
                            'expedited-shipping':
                                option.label === 'expedited_shipping',
                        }"
                    >
                        <p>
                            {{
                                capitalizeFirstLetter(
                                    snakeCaseToSpace(option.label)
                                )
                            }}
                        </p>
                        <p>
                            {{ formatPrice(option) }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InfoPopoverComponent from "@/components/InfoPopoverComponent.vue";
import { capitalizeFirstLetter, snakeCaseToSpace } from "@/utilities/strings";
import { useOrderStore } from "@/stores/order";
import { useCartStore } from "@/stores/cart";
import { currencySymbol } from "@/utilities/currency";

export default {
    name: "ShippingUpgrade",
    components: { InfoPopoverComponent },
    props: {
        order: Object,
    },
    data() {
        return {
            shipping_options: [],
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
        };
    },
    mounted() {
        let availableShippingUpgrades =
            this.orderStore.order_data.available_upgrades.shipping_upgrades;

        if (
            availableShippingUpgrades &&
            this.orderStore.order_data?.customer_data?.orders?.length === 1 &&
            this.orderStore.order_data?.customer_data?.orderShipping !==
                "expedited_shipping"
        ) {
            let shipping_options = [];

            if (!this.order.shipping_upgrade) {
                shipping_options.push({
                    current: !this.order.shipping_upgrade,
                    selected: !this.order.shipping_upgrade,
                    label: "Free Shipping",
                    amount: 0,
                    currency: "USD",
                    is_void: true,
                });
            }

            for (let i = 0; i < availableShippingUpgrades.length; i++) {
                let elm = Object.assign({}, availableShippingUpgrades[i]);

                elm.current = false;
                elm.selected = false;
                elm.label = elm.shipping_upgrade;

                if (elm.shipping_upgrade === this.order.shipping_upgrade) {
                    elm.current = true;
                    elm.selected = true;
                }

                shipping_options.push(elm);
            }

            if (shipping_options.length > 1) {
                this.shipping_options = shipping_options;
            }
        }
    },
    unmounted() {
        this.cartStore.removeFromCart("shipping_upgrade");
        this.$emit("shippingUpgradeUpdate", 0);
    },
    methods: {
        snakeCaseToSpace,
        capitalizeFirstLetter,
        selectOption(option) {
            this.shipping_options.forEach((elm) => {
                elm.selected = elm.label === option.label;

                if (elm.selected) {
                    if (elm.is_void) {
                        this.cartStore.removeFromCart("shipping_upgrade");
                    } else {
                        this.cartStore.addToCart(this.order, {
                            shipping_upgrade: elm,
                        });
                    }

                    this.$emit("shippingUpgradeUpdate", this.getUpgradeValue(elm));
                }
            });
        },
        getUpgradeValue(option) {
            return option.amount * this.order.quantity;
        },
        formatPrice(option) {
            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                this.getUpgradeValue(option)
            );
        },
    },
};
</script>

<style scoped lang="scss">
h3 {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
}

.col {
    padding-left: 6px;
    padding-right: 6px;
}

a.option {
    text-align: center;
    color: #392f5a;
    background-color: #ebeaee;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding: 8px;
    outline: 1px solid #dad8e0;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    &.selected {
        outline: 2px solid #392f5a;
    }

    &.disabled {
        cursor: default;
        background: lighten(#ebeaee, 10%);
        outline-color: lighten(#8d8b88, 2%);
        color: lighten(#9084b5, 2%);
    }

    p {
        margin: 5px 0;
    }
}
</style>
